import type { FormKitNode } from '@formkit/core'
import type { AddComment } from '~/types/calendar'
import { showToast } from '~/utils/showToast'

export async function validateForm(id: string) {
  const { getNode } = await import('@formkit/core')
  const form = getNode(id)
  form?.submit()
}

export async function checkFormBeforeLeaveRoute(
  form: { node: FormKitNode } | undefined,
  originalData: any | undefined,
  modifiedData: any | undefined,
) {
  if (
    !form ||
    form.node.context?.state.submitted ||
    !form.node.context?.state.dirty
  )
    return true

  // If originalData is undefined, it means that the form is new
  if (!originalData && modifiedData) {
    return Boolean(await leaveRouteModal())
  }

  // Deep compare objects
  const areObjectsEqual = Object.keys(originalData).every((key) => {
    return (
      JSON.stringify(originalData[key]) === JSON.stringify(modifiedData[key])
    )
  })

  if (areObjectsEqual) return true

  return Boolean(await leaveRouteModal())
}

export async function getStaff() {
  const clinicStore = useClinicStore()
  const nuxtApp = useNuxtApp()

  await nuxtApp.$api
    .getStaff()
    .then((response) => {
      clinicStore.setStaff(response)
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
      window.localStorage.removeItem('clinic')
      refreshToHome()
    })
}

export function deleteStaff(id: string) {
  const nuxtApp = useNuxtApp()

  nuxtApp.$swal
    .fire({
      title: 'Tem a certeza que quer remover este membro staff?',
      text: 'Não é possível reverter esta ação!',
      icon: 'warning',
      confirmButtonText: 'Remover',
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        await nuxtApp.$api
          .sendConfirmationToDeleteUser(id)
          .then(() => {
            showToast(
              'success',
              'Sucesso',
              'O proprietário da clínica irá receber um email a confirmar a remoção do membro staff.',
            )
          })
          .catch((error) => {
            showToast('error', 'Erro', error)
          })
      }
    })
    .catch(() => {
      showToast('error', 'Erro', 'Ocorreu um erro ao remover o membro staff.')
    })
}

export async function deleteAppointment(
  isDeleted: boolean,
  appointmentId: string,
  callback?: () => void,
) {
  const nuxtApp = useNuxtApp()

  const { appointmentReasonList } = await import('~/data/appointmentReasonList')

  // If appointment is already deleted, ask if user wants to delete permanently
  if (isDeleted) {
    nuxtApp.$swal
      .fire({
        title:
          'Esta marcação foi previamente desmarcada, deseja remover permanentemente esta marcação?',
        text: 'Não é possível reverter esta ação!',
        icon: 'warning',
        confirmButtonText: 'Remover',
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await nuxtApp.$api
            .deletePermanentlyAppointment(appointmentId)
            .then(() => {
              showToast(
                'success',
                'Sucesso',
                'Marcação desmarcada com sucesso.',
              )
              if (callback) callback()
            })
            .catch((error) => {
              showToast('error', 'Erro', error)
            })
        }
      })
      .catch((error) => {
        showToast('error', 'Erro', error)
      })
    return
  }

  let otherReasonConfirmed = false
  let appointmentReason = ''
  await nuxtApp.$swal.fire({
    title: 'Cancelar esta marcação',
    icon: 'warning',
    confirmButtonText: `Cancelar`,
    cancelButtonText: 'Fechar',
    input: 'select',
    inputOptions: appointmentReasonList,
    inputPlaceholder: 'Motivo da Desmarcação',
    inputValidator: (value) =>
      new Promise((resolve) => {
        const select = nuxtApp.$swal.getInput() as unknown as HTMLSelectElement
        const selectedText = select.options[select.selectedIndex].text

        if (!value) {
          resolve('Por favor, selecione um motivo.')
          return
        }

        if (selectedText === 'Outra razão') {
          otherReasonConfirmed = true
          resolve()
          return
        }

        appointmentReason = selectedText
        resolve()
      }),
  })

  if (otherReasonConfirmed) {
    await nuxtApp.$swal.fire({
      title: 'Cancelar esta marcação',
      icon: 'warning',
      confirmButtonText: `Cancelar`,
      cancelButtonText: 'Fechar',
      input: 'text',
      inputPlaceholder: 'Motivo da Desmarcação',
      inputValidator: (result) => {
        if (!result) {
          return 'Por favor, introduza um motivo.'
        }
        appointmentReason = result
      },
    })
  }

  if (!appointmentReason) return

  await nuxtApp.$api
    .deleteAppointment(appointmentId, appointmentReason)
    .then(() => {
      showToast('success', 'Sucesso', 'Marcação desmarcada com sucesso.')
      if (callback) callback()
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}

export function deleteReminder(id: string, callback?: () => void) {
  const nuxtApp = useNuxtApp()

  nuxtApp.$swal
    .fire({
      title: 'Tem a certeza que quer remover este lembrete?',
      text: 'Não é possível reverter esta ação!',
      icon: 'warning',
      confirmButtonText: 'Remover',
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        await nuxtApp.$api
          .deleteReminder(id)
          .then(async () => {
            showToast('success', 'Sucesso', 'Lembrete removido com sucesso.')
            if (callback) callback()
          })
          .catch((error) => {
            showToast('error', 'Erro', error)
          })
      }
    })
    .catch(() => {
      showToast('error', 'Erro', 'Ocorreu um erro ao remover o lembrete.')
    })
}

export async function addAppointmentComment(
  appointmentId: string,
  author: string,
  comment: string,
) {
  const nuxtApp = useNuxtApp()

  await nuxtApp.$api
    .addAppointmentComment(appointmentId, {
      content: comment,
      author,
      appointment: appointmentId,
    })
    .then(() => {
      showToast('success', 'Sucesso', 'Comentário adicionado com sucesso.')
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}

export async function updateAppointmentComment(
  appointmentId: string,
  commentId: string,
  comment: AddComment,
) {
  const nuxtApp = useNuxtApp()

  await nuxtApp.$api
    .updateAppointmentComment(appointmentId, commentId, comment)
    .then(() => {
      showToast('success', 'Sucesso', 'Comentário alterado com sucesso.')
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}

export async function deleteComment(appointment: string, commentId: string) {
  const nuxtApp = useNuxtApp()

  await nuxtApp.$swal
    .fire({
      title: 'Tem a certeza que deseja apagar este comentário?',
      text: 'Não é possível reverter esta ação!',
      icon: 'warning',
      confirmButtonText: `Apagar Comentário`,
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        await nuxtApp.$api
          .deleteAppointmentComment(appointment, commentId)
          .then(() => {
            showToast('success', 'Sucesso', 'Comentário removido com sucesso.')
          })
          .catch((error) => {
            showToast('error', 'Erro', error)
          })
      }
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}

export async function getPatientAppointments(patientId: string) {
  const nuxtApp = useNuxtApp()

  return await nuxtApp.$api
    .getPatientAppointments(patientId, 5)
    .then(async (response) => {
      // Sort appointments by date
      if (response.items.length > 0) {
        response.items = response.items.sort(
          (objA, objB) =>
            new Date(objB.schedule_start).getTime() -
            new Date(objA.schedule_start).getTime(),
        )
      }
      return response
    })
    .catch((error) => {
      showToast('error', 'Erro', error)
    })
}
